import axios from "axios";
import { dispatchtoast, removeLocalStorageItem, showError } from "../../Utils";

export const version = "api/v1/";
export const APIURL =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.cms.equity2commodity.com/api/v1/lms`
    : "https://prod.cms.equity2commodity.com/api/v1/lms";
const handleResponse = (response) => {
  if (response.data.status || response.data.success) {
    return response.data;
  } else if (response.data.formErrors) {
    showError(response.data.formErrors);
  } else if (response.data.validationError) {
    showError(response.data.validationError);
  } else {
    dispatchtoast(response.data.message ?? response.data.error, true);
  }
  return response.data;
};
const handleError = (error) => {
  const { status } = error?.response;
  if (status === 401) {
    dispatchtoast(
      error?.response.data.message ?? error?.response.data.msg,
      true
    );
    removeLocalStorageItem("isLoggedIn");
    removeLocalStorageItem("accessToken");
    window.location.href = "/";
  }
  if (status && status >= 400 && status < 500) {
    if (error.response.data.formErrors) {
      showError(error.response.data.formErrors);
    } else if (error.response.data.validationError) {
      showError(error.response.data.validationError);
    } else {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.error,
        true
      );
    }
  } else if (status && status >= 500) {
    dispatchtoast(
      error?.response.data.message ?? error?.response.data.msg,
      true
    );
  }
  return Promise.reject(error);
};
export const UPLOAD_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.content.equity2commodity.com/`
    : "https://prod.content.equity2commodity.com/";
export const AUTH_ENDPOINT = `https://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.auth.equity2commodity.com/${version}`;

export const AuthService = axios.create({
  // withCredentials: true,
  baseURL: AUTH_ENDPOINT,
});

// Add a request interceptor
AuthService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
AuthService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
);

export const COURSE_ENDPOINT = `https://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.course.equity2commodity.com/${version}`;
export const CourseService = axios.create({
  // withCredentials: true,
  baseURL: COURSE_ENDPOINT,
});

// Add a request interceptor
CourseService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
CourseService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
);

export const CHAT_ENDPOINT = `https://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.chat.equity2commodity.com/${version}`;
export const CHAT_SOCKET_ENDPOINT = `wss://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.chat.equity2commodity.com`;

export const ChatService = axios.create({
  // withCredentials: true,
  baseURL: CHAT_ENDPOINT,
});

// Add a request interceptor
ChatService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
ChatService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
);

export const USER_ENDPOINT = `https://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.user.equity2commodity.com/${version}`;
export const USER_ENDPOINT1 = `https://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.user.equity2commodity.com/`;

export const UserService = axios.create({
  // withCredentials: true,
  baseURL: USER_ENDPOINT,
});

// Add a request interceptor
UserService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
UserService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
);

export const Order_ENDPOINT = `https://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.order.equity2commodity.com/${version}`;

export const OrderService = axios.create({
  // withCredentials: true,
  baseURL: Order_ENDPOINT,
});

// Add a request interceptor
OrderService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
OrderService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
);

export const Notification_ENDPOINT = `https://${
  process.env.REACT_APP_NODE_ENV === "staging" ? "staging" : "prod"
}.notification.equity2commodity.com/${version}`;
export const NotificationService = axios.create({
  // withCredentials: true,
  baseURL: Notification_ENDPOINT,
});

// Add a request interceptor
NotificationService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
NotificationService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
);
