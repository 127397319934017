import { Get, getAPIUrl, Post } from "../../apiMethod";
import { AuthService } from "../../Axios/Index";
import { Authendpoint } from "./AuthEndpoint";


export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(AuthService, url, data, false);
};

export const userRegister = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.SignUp, param);
  return Post(AuthService,url, data, false);
};
export const ForgotPassoword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.forgotPassword, param);
  return Post(AuthService,url, data, false);
};
export const resetPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resetPassword, param);
  return Post(AuthService,url, data, false);
};
export const GoogleAuth = ( param = "") => {
  const url = getAPIUrl(Authendpoint.googleauth, param);
  return Get(AuthService,url, false);
};
export const FacebookAuth = ( param = "") => {
  const url = getAPIUrl(Authendpoint.facebookauth, param);
  return Get(AuthService,url, false);
};
export const GoogleVerify = ( param = "") => {
  const url = getAPIUrl(Authendpoint.googleVerify, param);
  return Get(AuthService,url, false);
};
export const FacebookVerify = ( param = "") => {
  const url = getAPIUrl(Authendpoint.facebookverify, param);
  return Get(AuthService,url, false);
};
export const updatePassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.updatePassword, param);
  return Post(AuthService,url,data);
};